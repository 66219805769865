body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

a {
  cursor: pointer;
}

h1, h2, h3, h4, h5, .h5 {
  font-family: Poppins;
  font-weight: 500;
}

p {
  font-family: Roboto;
  color: #231F20;
}

h1.dot {
  display: inline;
  margin: 0;
}

.red {
  color: #FF4136;
}

h1.underline {
  margin: 0;
  text-decoration: underline #FF4136;
  display: inline;
}

.btn-osc {
  color: white;
  background-color: #FF4136;
  border-color: #FF4136;
  font-family: Poppins;
  font-weight: 500;
}

.btn-osc:hover {
  color: white;
  background-color: #d63a32;
  border-color: #d63a32;
}

.bg-red {
  background: #fff3f2;
}