footer {
  /* background: #231F20; */
  background: #201E1E;
  /* background: url(../../assets/images/fundo_estrelado.png); */
  color: #fff;
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3rem;
}

footer a {
  padding-left: 1rem;
}

footer img {
  width: 100px;
}